<template lang="">
    <div class="container">
        <div class="mb-3">
            <label class="form-label">ملاحظـة</label>
            <input class="form-control" type="text" v-model="note" />
        </div>

        <div class="mb-3">


        </div>

        <v-select
              name="sku"
              class="w-full"
              data-vv-as="sku"
              data-vv-scope="order-items"
              label="sku"
              :options="product_skus"
              v-model="product_id"
            :reduce="(item) => item.id"
              
            
            
          
            v-validate="'required'"
              :tabindex="4"
              :filterable="true"
            ></v-select>
        <div class="mb-3">
            <button class="btn btn-dark" type="button" @click="saveCategory">حفظ التصنيف</button>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import vSelect from "vue-select";
export default {
    name:'new-category',
    data(){
        return {
            product_id:'',
            note:'',
            product_skus:''
        }
    },
    created(){
      this.getCategory();
  },

    methods: {
       async saveCategory(){
        try {
            let formData = new FormData();
            formData.append('note', this.note);
            formData.append('product_id', this.product_id);


            await axios.post('https://boutiquette-store.ly/add-product-to-highlight', formData);

            this.note = ''
            this.product_id = ''
            this.$router.push('/home');
            
        } catch (error) {
            null
        }
       },

       async getCategory(){
          try {
              const response = await axios.get('https://boutiquette-store.ly/products/skus')
              this.product_skus = response.data.data
          
         
       
          } catch (error) {
            null
          }
      },

      uploadImage(event) {
        this.image = event.target.files[0];
    },

    uploadSecondImage(event){
        this.second_image = event.target.files[0];
    }

      
    },
    components: {
    
    vSelect,
  },

}
</script>
<style lang="">
    
</style>